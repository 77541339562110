var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"seaContainer"},[(!_vm.isMobile)?_c('div',{staticClass:"textxxx"},[_c('h1',{staticStyle:{"color":"white"}},[_vm._v("Ops, página não encontrada")]),_c('h5',{staticStyle:{"color":"white"}},[_vm._v("Infelizmente esta página não foi localizada no sistema")])]):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"textxxx"},[_c('h5',{staticStyle:{"color":"white"}},[_vm._v("Ops, página não encontrada")]),_c('p',{staticStyle:{"color":"white","font-size":"small"}},[_vm._v("Infelizmente esta página não foi localizada no sistema")])]):_vm._e(),_vm._m(0),_vm._m(1),_c('div',{staticClass:"white-button"},[_c('BaseButton',{attrs:{"variant":"outline-white"},on:{"click":function($event){return _vm.redirect()}}},[_vm._v(" Voltar à página principal ")])],1),_vm._m(2),(!_vm.isGreenn)?_c('div',{staticClass:"midias"},[_vm._m(3),_vm._m(4),_vm._m(5)]):_vm._e(),(_vm.isGreenn)?_c('div',{staticClass:"logo"},[_c('a',{on:{"click":function($event){return _vm.redirect()}}},[_c('img',{staticStyle:{"width":"136px"},attrs:{"src":require("@/assets/greenn_sales/GREENN_SALES_4.png")}})])]):_c('div',{staticClass:"logo"},[_c('a',{on:{"click":function($event){return _vm.redirect()}}},[_c('img',{attrs:{"src":require("@/assets/logo-gdigital-branca.png")}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"submarine__container"},[_c('div',{staticClass:"light"}),_c('div',{staticClass:"submarine__periscope"}),_c('div',{staticClass:"submarine__periscope-glass"}),_c('div',{staticClass:"submarine__sail"},[_c('div',{staticClass:"submarine__sail-shadow dark1"}),_c('div',{staticClass:"submarine__sail-shadow light1"}),_c('div',{staticClass:"submarine__sail-shadow dark2"})]),_c('div',{staticClass:"submarine__body"},[_c('div',{staticClass:"submarine__window one"}),_c('div',{staticClass:"submarine__window two"}),_c('div',{staticClass:"submarine__shadow-dark"}),_c('div',{staticClass:"submarine__shadow-light"}),_c('div',{staticClass:"submarine__shadow-arcLight"})]),_c('div',{staticClass:"submarine__propeller"},[_c('div',{staticClass:"propeller__perspective"},[_c('div',{staticClass:"submarine__propeller-parts darkOne"}),_c('div',{staticClass:"submarine__propeller-parts lightOne"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bubbles__container"},[_c('span',{staticClass:"bubbles bubble-1"}),_c('span',{staticClass:"bubbles bubble-2"}),_c('span',{staticClass:"bubbles bubble-3"}),_c('span',{staticClass:"bubbles bubble-4"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ground__container"},[_c('div',{staticClass:"ground ground1"},[_c('span',{staticClass:"up-1"}),_c('span',{staticClass:"up-2"}),_c('span',{staticClass:"up-3"}),_c('span',{staticClass:"up-4"}),_c('span',{staticClass:"up-5"}),_c('span',{staticClass:"up-6"}),_c('span',{staticClass:"up-7"}),_c('span',{staticClass:"up-8"}),_c('span',{staticClass:"up-9"}),_c('span',{staticClass:"up-10"})]),_c('div',{staticClass:"ground ground2"},[_c('span',{staticClass:"up-1"}),_c('span',{staticClass:"up-2"}),_c('span',{staticClass:"up-3"}),_c('span',{staticClass:"up-4"}),_c('span',{staticClass:"up-5"}),_c('span',{staticClass:"up-6"}),_c('span',{staticClass:"up-7"}),_c('span',{staticClass:"up-8"}),_c('span',{staticClass:"up-9"}),_c('span',{staticClass:"up-10"}),_c('span',{staticClass:"up-11"}),_c('span',{staticClass:"up-12"}),_c('span',{staticClass:"up-13"}),_c('span',{staticClass:"up-14"}),_c('span',{staticClass:"up-15"}),_c('span',{staticClass:"up-16"}),_c('span',{staticClass:"up-17"}),_c('span',{staticClass:"up-18"}),_c('span',{staticClass:"up-19"}),_c('span',{staticClass:"up-20"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.facebook.com/gdigitalbr/","target":"_blank"}},[_c('img',{staticClass:"img-icon",attrs:{"src":require("@/assets/icons/facebookNew.svg"),"alt":"icon"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.instagram.com/gdigitaloficial/","target":"_blank"}},[_c('img',{staticClass:"img-icon",attrs:{"src":require("@/assets/icons/instagram.svg"),"alt":"icon"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.youtube.com/channel/UClETYeE-kuDLqFZxZeCXEsg","target":"_blank"}},[_c('img',{staticClass:"img-icon",attrs:{"src":require("@/assets/icons/youtube.svg"),"alt":"icon"}})])
}]

export { render, staticRenderFns }